<template>
    <div class="my_treat_page">
        <van-sticky :offset-top="0">
            <div class="page_top" style="background-color: #FAFAFA;">
                
                <van-search class="search_div" input-align="center" background="#fff" left-icon="none" @clear="keyword = ''"  @search="onSearch(keyword)" v-model="keyword" placeholder="请输入会员/家属姓名" />
                <div class="date_choose" style="background-color: #fff;border: unset;">
                    <span style="font-size: 14px;" class="date_info" @click="chooseTreat">{{treat || '请选择理疗项目'}}</span>
                    <i class="icon iconfont iconarrow-bottom-filling"></i>
                </div>
            </div>
        </van-sticky>
        <div class="treat_main_list"  >
        <van-list
            v-model="loading"
            :finished="finished"
            :offset="30"
            :immediate-check="false"
            finished-text="没有更多了"
            v-if="list.length > 0"
            @load="gettriageprojectqry"
            >
            <div class="one"  v-for="(item,index) in list" :key="index">
                <div class="name_appointment">
                    <div class="left">{{item.name}}</div>
                    <div class="right">
                        <div class="detail_info" @click="medical(item.customerid,item.userid)">
                            <span>患者病历</span>
                            <i class="icon iconfont iconsanjiaoyou"></i>
                        </div>
                    </div>
                </div>
                <div class="sex_age_phone">
                    <span>{{item.sex }} &nbsp;{{item.age}}岁</span>
                    <span v-if="item.phone">{{item.phone}}</span>
                </div>
                <div class="title_top" v-for="childid in item.projectlist" :key="childid.projectcode">
                    <div class="left">{{childid.projectname}}</div>
                    <div class="right" style="font-size: 12px;">剩余次数： <span style="color: #EE0A23;">{{childid.surplusnum}}</span><span>（订购次数：{{+childid.execnum + +childid.surplusnum}}）</span></div>
                </div>

                <div style="display: flex;justify-content: center;">
                    <div @click="$router.push({path:'/assignTreat',query:{userid:item.userid,customerid:item.customerid,name:item.name || '',sex:item.sex || '',age:item.age || '',phone:item.phone || ''}})" style="background-color: #CF8C57;width: 100px;height: 38px;text-align: center;line-height: 38px;color: #fff;border-radius: 21px;">分诊</div>
                </div>
            </div>
        </van-list>
        <div class="has_no_data" v-else>
            当前无理疗数据
        </div>
        </div>
        <van-popup v-model="showPicker" round position="bottom">
            <van-picker
                title="请选择理疗项目"
                show-toolbar
                :columns="columns"
                @cancel="showPicker = false"
                @confirm="onConfirmDoctor"
            >
               <template #option="item"> {{ item.projectname }}</template>
            </van-picker>
        </van-popup>
    </div>
</template>
<script>
import { wxprojectlistqry,wxtriageprojectqry } from '@/api/medical'
import { mapGetters } from 'vuex'
export default{
    computed: {
            ...mapGetters([
                'username','dispname','clinicid'
            ]),
        },
    data(){
        return{
            keyword:'',
            treat:'',
            showPicker:false,
            columns:[],
            list: [],
            loading: false,
            finished: false,
            projectcode:'',
            page:0
        }
    },
    created() {
    this.$store.dispatch('hideOrShowNav',false)
    this.getprojectlist()
    this.gettriageprojectqry()
        
    },
    beforeRouteEnter(to, from, next) {
        if( from.path == '/assignTreat'){
            from.meta.keepAlive = false
            next()
        }else if(from.path == '/medicalPage'){
            next(vm =>{
                vm.$store.dispatch('hideOrShowNav',false)
                
            })
        }
        else{
            next(vm =>{
                vm.$store.dispatch('hideOrShowNav',false)
                vm.keyword = ''
                vm.treat = ''
                
            })
        }
        
    },
    beforeRouteLeave(to, from, next) {
        
        if(to.path == '/assignTreat'){
            from.meta.keepAlive = true
            
        }else if(to.path == '/medicalPage'){
            from.meta.keepAlive = true
        }
        else if(to.path == '/'){
            from.meta.keepAlive = false
        }
        next()
    },
    methods:{
        getprojectlist(){
            let data = {
                    username: this.username,
                }
                wxprojectlistqry(data).then(response =>{
           
                    this.columns = response.response_body.list
                    this.columns.unshift({
                        projectname:'全部',
                        projectcode:'',
                    })
                })
        },
        onConfirmDoctor(val){
      
            this.treat = val.projectname
            this.showPicker = false
            this.projectcode = val.projectcode

            this.page = 0
            this.list = []
            this.finished = false
            this.loading = false
            this.gettriageprojectqry(val.projectcode)

        },
        // 可分诊项目查询
        gettriageprojectqry(){
            this.page += 1
            let data = {
                    username: this.username,
                    projectcode:this.projectcode,
                    keyword:this.keyword,
                    userid:'',
                    customerid:'',
                    qrytype:'0',
                    qrypage:this.page,
                }
            wxtriageprojectqry(data).then(response =>{
              
                this.loading = false
                if(response.response_body.count){

                    this.list =[...this.list, ...response.response_body.list] || []
                    this.list.forEach(item =>{
                        item.phone = (item.phone).replace(/(\d{3})\d{4}(\d{4})/, "$1****$2") || ''
                    })
                    if(this.page >= response.response_body.pages){
                        this.finished = true
                    }
                }else{
                    this.list = []
                }
                
            })
        },
        onSearch(key){
            this.keyword = key
            this.page = 0
            this.list = []
            this.finished = false
            this.loading = false
            this.gettriageprojectqry()
        },
        chooseTreat(){
            this.showPicker = true
            
        },

        medical(customerid,userid) {
            this.$store.dispatch('changeIsRefresh', '1')
            this.$router.push({
                path: '/medicalPage',
                query: {
                    userid,
                    customerid,
                }
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.my_treat_page{
    margin-bottom: 0;
    min-height: 100vh;
    .page_top{
        background-color: unset;
    }
    .date_choose{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        .page_top .date_choose .date_info{
            color: #ccc;
        }
    }
}
.van-search__content{
    background-color: #fff;
}
.page_top .search_div{
    width: calc(100% - 3.8rem);
}
.van-search__content{
    padding-left: unset;
}
.iconarrow-bottom-filling{
    width: 0;
    height: 0;
    border-top: 5px solid #ccc;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}
.bai {
        background-color: #ffffff;
    }
    .btnimg{
        width: 50px;
        height: 50px;
        position: fixed;
        right: 10px;
        bottom: 200px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .treat_main_list{

        .one{
        background: linear-gradient( 180deg, rgba(207,140,87,0.2) 0%, rgba(255,249,244,0) 20%) !important;
        }
        .name_appointment .left{
            width: 170px;
            white-space: nowrap;      
            overflow: hidden;         
            text-overflow: ellipsis;
        }
    }
</style>